import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";
import axios from "axios";

export function useSync(
  onSuccessCustom?: (data: [GQL.V2ProductsSync, GQL.PricesSync, GQL.TaxRateSync, GQL.V2BundleSync]) => void
): UseMutationResult<[GQL.V2ProductsSync, GQL.PricesSync, GQL.TaxRateSync, GQL.V2BundleSync], ClientError> {
  return useMutation(
    "syncStripe",
    async () => {
      const data = await graphQLClient.request(
        gql`
          mutation {
            v2ProductsSync {
              productSynced
            }
            pricesSync {
              pricesSynced
            }
            taxRateSync {
              taxRateSynced
            }
            v2BundleSync {
              bundleSynced
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

export function usePrismicSync(
  onSuccessCustom?: (data: [GQL.V2ProductsSync, GQL.PricesSync, GQL.TaxRateSync, GQL.V2BundleSync]) => void
): UseMutationResult<[GQL.V2ProductsSync, GQL.PricesSync, GQL.TaxRateSync, GQL.V2BundleSync], ClientError> {
  return useMutation(
    "syncPrismic",
    async () => {
      const data = await graphQLClient.request(
        gql`
          mutation {
            prismicProductsSync {
              productsSynced
            }
            prismicBundlesSync {
              bundlesSynced
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

export function usePublish(
  onSuccessCustom?: (data: [GQL.V2Publish]) => void
): UseMutationResult<[GQL.V2BundleSync, GQL.V2Publish], ClientError> {
  return useMutation(
    "publish",
    async () => {
      const data = await graphQLClient.request(
        gql`
          mutation {
            v2BundleSync {
              bundleSynced
            }
            v2Publish {
              productPublished
              bundlePublished
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

export function useAmplifyAccRebuild(
  onSuccessCustom?: (data: [GQL.AmplifyAccRebuild]) => void
): UseMutationResult<[GQL.AmplifyAccRebuild], ClientError> {
  return useMutation(
    "amplifyAccRebuild",
    async () => {
      const data = await graphQLClient.request(
        gql`
          mutation {
            amplifyAccRebuild {
              response
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}
export function useAmplifyPrdRebuild(
  onSuccessCustom?: (data: [GQL.AmplifyPrdRebuild]) => void
): UseMutationResult<[GQL.AmplifyPrdRebuild], ClientError> {
  return useMutation(
    "amplifyPrdRebuild",
    async () => {
      const data = await graphQLClient.request(
        gql`
          mutation {
            amplifyPrdRebuild {
              response
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

// export function useArticlesCreate(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesCreate, ClientError, GQL.IArticlesCreateOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`

//             mutation{
//               articlesCreate(articleDate: "${moment(dataMutation.articleDate).format("YYYYMMDD")}",
//               companyId: "${dataMutation.companyId}",
//               description: """${dataMutation.description}""",
//               documentUrl: "${dataMutation.documentUrl}",
//               isPrivate: ${dataMutation.isPrivate}){
//                 success
//               }
//             }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }

// export function useArticlesDelete(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesDelete, ClientError, GQL.IArticlesDeleteOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//           mutation{
//             articlesDelete(articlesUuid: "${dataMutation.articlesUuid}"){
//               success
//             }
//           }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }
