import TagManager from "react-gtm-module";
import { __DEV__, __ENV__, adobe_datalayer_launch_link, PAGEVIEW } from "../api";
import useScript from "../hooks/useScript";
import { round2digits } from "./utils";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    adobeDataLayer: Record<string, any>[];
  }
}

const isSunstarDomain = window.location.host.includes("sunstargum.com");

export const initTrackingEvents = () => {
  // TRACKING INIT

  if (isSunstarDomain) {
    AdobeDataLayerTrackingInit();
  } else {
    const tagManagerArgs = {
      gtmId: "GTM-MC2FGS2",
    };
    TagManager.initialize(tagManagerArgs);
  }

  window.adobeDataLayer = window.adobeDataLayer || [];
};

export const AdobeDataLayerTrackingInit = () => {
  useScript("/acdl/adobe-client-data-layer.min.js");
  useScript(adobe_datalayer_launch_link);

  const link_1 = document.createElement("link");
  link_1.rel = "preconnect";
  link_1.href = "https://privacy-proxy.usercentrics.eu";
  document.body.appendChild(link_1);

  const link_2 = document.createElement("link");
  link_2.rel = "preload";
  link_2.href = "https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js";
  link_2.as = "script";
  document.body.appendChild(link_2);

  const script_1 = document.createElement("script");
  script_1.id = "usercentrics-cmp";
  script_1.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js";
  script_1.setAttribute("data-settings-id", "6FvKRPaLS");
  script_1.async = true;
  document.body.appendChild(script_1);

  const script_2 = document.createElement("script");
  script_2.type = "application/javascript";
  script_2.src = "https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js";
  script_2.text = "uc.deactivateBlocking(['f6nkjdUL']); // Template ID for Adobe Experience Cloud";
  document.body.appendChild(script_2);
};

const cartToEventItemList = (cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null) => {
  let items_list = [];
  if (cartData && cartData.lineItems) {
    for (const item of cartData?.lineItems) {
      if (item && item.lineContent && item.lineContent.length > 0) {
        // for (const [itemLineKey, itemLine] of Object.entries(item.lineContent || [])) {
        for (const itemLine of item.lineContent) {
          items_list.push({
            // item_id: item?.lineId + "-" + itemLine?.lineId,
            item_id: item?.lineId,
            item_name: itemLine?.productName,
            discount: Math.max((itemLine?.priceDefaultAmount || 0) - (itemLine?.priceAmount || 0) / 100, 0),
            index: items_list.length,
            // item_category: '"& 'Event Properties Reference'!$C$25&"',
            item_brand: "Playbrush",
            item_list_id: item?.lineId,
            item_list_name: item?.productName,
            item_variant: itemLine?.productName,
            price: (itemLine?.priceAmount || 0) / 100,
            quantity: (itemLine?.quantity || 0) * (item?.quantity || 0),
          });
        }
      } else {
        items_list.push({
          // item_id: item?.lineId,
          item_id: item?.product,
          item_name: item?.productName,
          discount: Math.max(((item?.priceDefaultAmount || 0) - (item?.priceAmount || 0)) / 100, 0),
          index: items_list.length,
          // item_category: '"& 'Event Properties Reference'!$C$25&"',
          item_brand: "Playbrush",
          item_list_id: item?.product,
          item_list_name: item?.productName,
          item_variant: item?.productName,
          price: (item?.priceAmount || 0) / 100,
          quantity: item?.quantity || 0,
        });
      }
    }
  }
  return items_list;
};

const cartToEventBundleItemList = (cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null) => {
  let items_list = [];
  if (cartData && cartData.lineItems) {
    for (const item of cartData?.lineItems) {
      if (item && item.lineContent && item.lineContent.length > 0) {
        if (item?.frontendLineData && item?.frontendLineData.trackingContext) {
          items_list.push(item?.frontendLineData.trackingContext);
        } else {
          items_list.push({
            item_id: item?.lineId,
            item_name: item?.productName,
            discount: Math.max((item?.priceDefaultAmount || 0) - (item?.priceAmount || 0) / 100, 0),
            index: items_list.length,
            item_category:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_category
                : "",
            item_brand:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_brand
                : "Playbrush Bundle",
            item_list_id:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_list_id
                : item?.lineId,
            item_list_name:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_list_name
                : item?.productName,
            item_variant: item?.productName,
            price: (item?.priceAmount || 0) / 100,
            quantity: item?.quantity || 0,
          });
        }
      } else {
        if (item?.frontendLineData && item?.frontendLineData.trackingContext) {
          items_list.push(item?.frontendLineData.trackingContext);
        } else {
          items_list.push({
            // item_id: item?.lineId,
            item_id: item?.product,
            item_name: item?.productName,
            discount: Math.max(((item?.priceDefaultAmount || 0) - (item?.priceAmount || 0)) / 100, 0),
            index: items_list.length,
            item_category:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_category
                : "",
            item_brand:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_brand
                : "Playbrush Product",
            item_list_id:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_list_id
                : item?.product,
            item_list_name:
              item?.frontendLineData && item?.frontendLineData.trackingContext
                ? item?.frontendLineData.trackingContext.item_list_name
                : item?.productName,
            item_variant: item?.productName,
            price: (item?.priceAmount || 0) / 100,
            quantity: item?.quantity || 0,
          });
        }
      }
    }
  }
  return items_list;
};

const cartToLinksterBasketItemList = (
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null,
  campaignId: number,
  orderId: string
) => {
  const cookieEmid = document.cookie.match(/emid\=([a-zA-Z0-9\-\_]+)/i);
  const emid = window.localStorage["emid"] || (cookieEmid === null || cookieEmid === void 0 ? void 0 : cookieEmid[1]);

  let items_list = [];
  if (
    cartData &&
    cartData.lineItems &&
    cartData.amountTotal !== null &&
    cartData.amountDiscount !== null &&
    cartData.amountTax !== null &&
    cartData.amountShipping !== null &&
    cartData.amountTotal !== undefined &&
    cartData.amountDiscount !== undefined &&
    cartData.amountTax !== undefined &&
    cartData.amountShipping !== undefined
  ) {
    const tax_rate = round2digits(cartData?.amountTotal / (cartData?.amountTotal - (cartData?.amountTax || 0)) - 1);
    for (const item of cartData?.lineItems) {
      if (item && item.lineContent && item.lineContent.length > 0) {
        for (const itemLine of item.lineContent) {
          items_list.push({
            campaign_id: campaignId,
            token: orderId,
            trigger_id: 2,
            article_number: itemLine?.product,
            productname: itemLine?.productName,
            category: "product",
            amount: itemLine?.quantity || 0,
            price: round2digits(
              (itemLine?.priceAmount || 0) /
                100 /
                // NET price calculation Subtract 19 % VAT from price: 26.99 EUR / 1.19 ≈ 22.68 EUR
                (tax_rate + 1)
            ).toString(),
            attribution: 1,
            emid: emid,
          });
        }
      } else {
        items_list.push({
          campaign_id: campaignId,
          token: orderId,
          trigger_id: 2,
          article_number: item?.product,
          productname: item?.productNameOrder || item?.productName,
          category: "product",
          amount: item?.quantity || 0,
          price: round2digits(
            (item?.priceAmount || 0) /
              100 /
              // NET price calculation Subtract 19 % VAT from price: 26.99 EUR / 1.19 ≈ 22.68 EUR
              (tax_rate + 1)
          ).toString(),
          attribution: 1,
          emid: emid,
        });
      }
    }
    items_list.push({
      campaign_id: campaignId,
      token: orderId,
      trigger_id: 1,
      article_number: "shipping",
      productname: "shipping",
      category: "shipping",
      amount: 1,
      price: round2digits(
        cartData?.amountShipping /
          100 /
          // NET price calculation Subtract 19 % VAT from price: 26.99 EUR / 1.19 ≈ 22.68 EUR
          (tax_rate + 1)
      ).toString(),
      attribution: 1,
      emid: emid,
    });
    items_list.push({
      campaign_id: campaignId,
      token: orderId,
      trigger_id: 1,
      article_number: "discount",
      productname: "discount",
      category: "discount",
      amount: 1,
      price: round2digits(
        (cartData?.amountDiscount /
          100 /
          // NET price calculation Subtract 19 % VAT from price: 26.99 EUR / 1.19 ≈ 22.68 EUR
          (tax_rate + 1)) *
          -1
      ).toString(),
      attribution: 1,
      emid: emid,
    });
    items_list.push({
      campaign_id: campaignId,
      token: orderId,
      trigger_id: 1,
      article_number: "taxes",
      productname: "taxes",
      category: "taxes",
      amount: 1,
      price: round2digits(cartData?.amountTax / 100).toString(),
      attribution: 1,
      emid: emid,
    });
  }
  return items_list;
};

export const beginCheckoutTrackingEvent = (cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null) => {
  const event_data = {
    event: "begin_checkout",
    ecommerce: {
      value: (cartData?.amountTotal || 0) / 100,
      currency: cartData?.currency?.toUpperCase() || "EUR",
      coupon: cartData?.couponCode || "",
      // shipping_tier: "First Class",
      items: cartToEventItemList(cartData),
    },
  };
  const event_data_adobe = {
    ...event_data,
    eventInfo: {
      path: "ecommerce",
    },
    ecommerce: {
      ...event_data.ecommerce,
      items: cartToEventBundleItemList(cartData),
    },
  };

  if (isSunstarDomain) {
    if (__DEV__) {
      console.log("BEGIN_CHECKOUT_INFO_AEM_EVENT", event_data_adobe);
    }
    window.adobeDataLayer.push(event_data_adobe);
  } else {
    if (__DEV__) {
      console.log("BEGIN_CHECKOUT_INFO_EVENT", event_data);
    }
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(event_data);
  }
};

export const addShippingInfoTrackingEvent = (cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null) => {
  const event_data = {
    event: "add_shipping_info",
    ecommerce: {
      value: (cartData?.amountTotal || 0) / 100,
      currency: cartData?.currency?.toUpperCase() || "EUR",
      coupon: cartData?.couponCode || "",
      // shipping_tier: "First Class",
      items: cartToEventItemList(cartData),
    },
  };

  const event_data_adobe = {
    ...event_data,
    eventInfo: {
      path: "ecommerce",
    },
    ecommerce: {
      ...event_data.ecommerce,
      items: cartToEventBundleItemList(cartData),
    },
  };

  if (isSunstarDomain) {
    if (__DEV__) {
      console.log("ADD_SHIPPING_INFO_AEM_EVENT", event_data_adobe);
    }
    window.adobeDataLayer.push(event_data_adobe);
  } else {
    if (__DEV__) {
      console.log("ADD_SHIPPING_INFO_EVENT", event_data);
    }
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(event_data);
  }
};

export const addPaymentInfoTrackingEvent = (
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null,
  payment_type: string
) => {
  const event_data = {
    event: "add_payment_info",
    ecommerce: {
      value: (cartData?.amountTotal || 0) / 100,
      tax: (cartData?.amountTax || 0) / 100,
      shipping: (cartData?.amountShipping || 0) / 100,
      currency: cartData?.currency?.toUpperCase() || "EUR",
      coupon: cartData?.couponCode || "",
      payment_type: payment_type,
      items: cartToEventItemList(cartData),
    },
  };

  const event_data_adobe = {
    ...event_data,
    eventInfo: {
      path: "ecommerce",
    },
    ecommerce: {
      ...event_data.ecommerce,
      items: cartToEventBundleItemList(cartData),
    },
  };

  if (isSunstarDomain) {
    if (__DEV__) {
      console.log("ADD_PAYMENT_INFO_AEM_EVENT", event_data_adobe);
    }
    window.adobeDataLayer.push(event_data_adobe);
  } else {
    if (__DEV__) {
      console.log("ADD_PAYMENT_INFO_EVENT", event_data);
    }
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(event_data);
  }
};

export const purchaseTrackingEvent = (cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null, orderId: string) => {
  const event_data = {
    event: "purchase",
    ecommerce: {
      transaction_id: orderId || "",
      value: (cartData?.amountTotal || 0) / 100,
      tax: (cartData?.amountTax || 0) / 100,
      shipping: (cartData?.amountShipping || 0) / 100,
      currency: cartData?.currency?.toUpperCase() || "EUR",
      coupon: cartData?.couponCode || "",
      items: cartToEventItemList(cartData),
    },
  };

  const event_data_adobe = {
    ...event_data,
    eventInfo: {
      path: "ecommerce",
    },
    ecommerce: {
      ...event_data.ecommerce,
      items: cartToEventBundleItemList(cartData),
    },
  };

  if (isSunstarDomain) {
    if (__DEV__) {
      console.log("PURCHASE_AEM_EVENT", event_data_adobe);
    }
    window.adobeDataLayer.push(event_data_adobe);
  } else {
    if (__DEV__) {
      console.log("PURCHASE_EVENT", event_data);
    }
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(event_data);
  }

  // Linkster integration (DISABLED)
  // https://trck.linkster.co/trck/ebasket/
  // https://docs.linkster.co/docs/tracking/tracking-methods/sale-conversion-tracking
  // const campaignId = 244;
  // const basket = cartToLinksterBasketItemList(cartData, campaignId, orderId);
  // const currency = cartData?.currency?.toUpperCase() || "EUR";
  // const orderCategory = "new";
  // const voucherCode = cartData?.couponCode || "";

  // var url = "https://trck.linkster.co/trck/ebasket/";
  // url += "?json=" + encodeURIComponent(JSON.stringify(basket)); // stringifying and encoding the basket in one line of code.
  // url += "&campaign_id=" + campaignId;
  // url += "&currency=" + currency;
  // url += "&order_category=" + orderCategory;
  // url += "&vc=" + encodeURIComponent(voucherCode);

  // if (__DEV__) {
  //   console.log("PURCHASE_LINKSTER_BASKET", basket);
  // }
  // if (__ENV__ === "production") {
  //   var req = new XMLHttpRequest();
  //   req.withCredentials = true;
  //   req.open("GET", url);
  //   req.send();
  // }
  //END Linkster integration
};

export const addPageViewTrackingEvent = (
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null,
  pageview: PAGEVIEW
) => {
  const event_data = {
    event: "cmp:show",
    eventInfo: {
      path: "page.page-67e7c25482",
    },
    page: {
      "page-67e7c25482": {
        "@type": "core/wcm/components/playbrush-checkout/page",
        "dc:description": "Checkout Page",
        "repo:modifyDate": "2024-08-21T00:00:00Z",
        "repo:path": pageview,
        "xdm:content": "playbrush",
        "xdm:flag": "BoFu",
        "xdm:language": cartData?.language?.toLowerCase(),
        "xdm:market": cartData?.country?.toUpperCase(),
        "xdm:template": "playbrush-checkout",
      },
    },
  };

  if (isSunstarDomain) {
    if (__DEV__) {
      console.log("PAGE_VIEW_EVENT", event_data);
    }
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(event_data);
  }
};
