import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { Elements, useElements, useStripe, ExpressCheckoutElement } from "@stripe/react-stripe-js";
import {
  ChangeResolveDetails,
  ClickResolveDetails,
  ShippingRate,
  StripeElementsUpdateOptions,
  StripeError,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElementReadyEvent,
  StripeExpressCheckoutElementShippingAddressChangeEvent,
  StripePaymentRequestButtonElement,
  loadStripe,
} from "@stripe/stripe-js";
import { useStateMachine } from "little-state-machine";
import { any } from "prop-types";
import { __DEV__, allowed_shipping_countries, PAGEVIEW, processENV } from "../api";
import { useCheckoutExpressShippingAddressChange, useCheckoutExpressSubmit } from "../hooks/useCheckout";
import { useCustomerCreate } from "../hooks/useCustomer";
import { Language } from "@mui/icons-material";
import { CheckoutContext } from "../context/checkoutContext";
import { CircularProgress } from "@mui/material";
import { LoaderWrapper, LoaderWrapperSmall } from "../screens/checkout";
import { addPageViewTrackingEvent, addPaymentInfoTrackingEvent } from "./trackingEvents";

const stripePromise = loadStripe(processENV.REACT_APP_STRIPE_CLIENT_SECRET || "", {
  apiVersion: processENV.REACT_APP_STRIPE_API_VERSION,
});

const StripeExpressCheckout = () => {
  const { state } = useStateMachine();
  let cartData = useContext(CheckoutContext);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const mutationCheckoutExpressSubmit = useCheckoutExpressSubmit();
  const mutationCheckoutExpressShippingAddressChange = useCheckoutExpressShippingAddressChange();

  const errorMessageHandler = (error: StripeError | null | undefined) => {
    if (error && error.message) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    } else {
      // The payment UI automatically closes with a success animation.
      // Your customer is redirected to your `return_url`.
    }
  };

  const expressCheckoutElementOptions: StripeExpressCheckoutElementOptions = {
    paymentMethodOrder: ["paypal", "apple_pay", "google_pay", "link"],
    layout: { maxColumns: 1, overflow: "never" },
    paymentMethods: { applePay: "always", googlePay: "always" },
  };

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (elements) {
      const { error: submitError } = await elements.submit();
      if (submitError && submitError !== undefined) {
        setErrorMessage(submitError.message || "");
        return;
      }
    }
    if (__DEV__) {
      console.log("onConfirm event:", event);
    }

    addPageViewTrackingEvent(cartData, PAGEVIEW.CHECKOUT_EXPRESS);

    const firstName = (event.shippingAddress?.name || "").trim().split(" ").slice(0, -1).join(" ");
    const lastName = (event.shippingAddress?.name || "").trim().split(" ").slice(-1).join(" ");
    let checkoutExpressSubmitData: GQL.MutationCheckoutExpressSubmitArgs = {
      checkoutId: cartData?.checkoutId || "",
      // checkoutId: state.data.checkoutId || "",
      name: (event.billingDetails?.name || "").trim(),
      email: (event.billingDetails?.email || "").trim(),
      description: "",
      address: {
        city: event.billingDetails?.address.city,
        country: event.billingDetails?.address.country,
        line1: event.billingDetails?.address.line1,
        line2: event.billingDetails?.address.line2,
        postalCode: event.billingDetails?.address.postal_code,
        state: event.billingDetails?.address.state,
      },
      shipping: {
        name: (event.shippingAddress?.name || "").trim(),
        phone: event.billingDetails?.phone,
        address: {
          city: event.shippingAddress?.address.city,
          country: event.shippingAddress?.address.country,
          line1: event.shippingAddress?.address.line1,
          line2: event.shippingAddress?.address.line2,
          postalCode: event.shippingAddress?.address.postal_code,
          state: event.shippingAddress?.address.state,
        },
      },
      phone: event.billingDetails?.phone,
      currency: cartData && cartData.currency ? cartData.currency.toLowerCase() : "eur",
      metadata: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        company_name: "",
      })
        .replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f"),
      firstName: firstName,
      lastName: lastName,
      companyName: "",
      expressPaymentType: event.expressPaymentType,
      language: cartData?.language || "EN",
    };

    mutationCheckoutExpressSubmit.mutate(checkoutExpressSubmitData, {
      onSuccess: async (dataExpress) => {
        if (__DEV__) {
          console.log("mutationCheckoutExpressSubmit Success", dataExpress);
        }
        if (elements) {
          addPaymentInfoTrackingEvent(cartData, "express_" + dataExpress.expressPaymentType);

          if (dataExpress.expressPaymentType === "paypal") {
            if (dataExpress.paymentIntentType === "setup") {
              const { error: StripeError } = await stripe.confirmPayPalSetup(dataExpress.stripeClientSecret || "", {
                return_url: processENV.REACT_APP_SITE_URL + "/checkout/" + state.data.checkoutId + "/processing",
              });
              errorMessageHandler(StripeError);
            } else if (dataExpress.paymentIntentType === "payment") {
              const { error: StripeError } = await stripe.confirmPayPalPayment(dataExpress.stripeClientSecret || "", {
                return_url: processENV.REACT_APP_SITE_URL + "/checkout/" + state.data.checkoutId + "/processing",
              });
              errorMessageHandler(StripeError);
            } else {
              setErrorMessage("Error happened - worng paymentIntentType received");
            }
          } else {
            if (dataExpress.paymentIntentType === "setup") {
              // Confirm the SetupIntent
              const { error: StripeError } = await stripe.confirmSetup({
                elements: elements,
                clientSecret: dataExpress.stripeClientSecret || "",
                confirmParams: {
                  return_url: processENV.REACT_APP_SITE_URL + "/checkout/" + state.data.checkoutId + "/processing",
                },
              });
              errorMessageHandler(StripeError);
            } else if (dataExpress.paymentIntentType === "payment") {
              // Confirm the PaymentIntent
              const { error: StripeError } = await stripe.confirmPayment({
                elements: elements,
                clientSecret: dataExpress.stripeClientSecret || "",
                confirmParams: {
                  return_url: processENV.REACT_APP_SITE_URL + "/checkout/" + state.data.checkoutId + "/processing",
                },
              });
              errorMessageHandler(StripeError);
            } else {
              setErrorMessage("Error happened - worng paymentIntentType received");
            }
          }
        }
      },
      onError: () => {},
    });
  };

  const onClick = (event: StripeExpressCheckoutElementClickEvent) => {
    const options: ClickResolveDetails = {
      emailRequired: true,
      phoneNumberRequired: true,
      shippingAddressRequired: true,
      allowedShippingCountries: allowed_shipping_countries,
      // wallets: { applePay: "always", googlePay: "always" },
      // applePay: {
      //   recurringPaymentRequest: {
      //     paymentDescription: "My subscription",
      //     managementURL: "https://example.com/billing",
      //     regularBilling: {
      //       amount: 2500,
      //       label: "Monthly subscription fee",
      //       recurringPaymentIntervalUnit: "month",
      //       recurringPaymentIntervalCount: 1,
      //     },
      //   },
      // },
      shippingRates: [
        {
          id: "free-shipping",
          displayName: "Free shipping",
          amount: 0,
          deliveryEstimate: {
            maximum: { unit: "day", value: 30 },
            minimum: { unit: "day", value: 7 },
          },
        },
      ],
    };
    event.resolve(options);
  };

  const onShippingAddressChange = (event: StripeExpressCheckoutElementShippingAddressChangeEvent) => {
    const { resolve, reject, address } = event;
    if (__DEV__) {
      console.log(event);
    }
    // handle shippingaddresschange event
    let shipping_address_data: GQL.MutationCheckoutExpressShippingAddressChangeArgs = {
      checkoutId: state.data.checkoutId,
      country: address.country,
    };
    mutationCheckoutExpressShippingAddressChange.mutate(shipping_address_data, {
      onSuccess: async (dataExpressShipping) => {
        if (__DEV__) {
          console.log("mutationCheckoutExpressShippingAddressChange Success", dataExpressShipping);
        }
        if (dataExpressShipping.shippingRates != null && dataExpressShipping.amountTotal != null) {
          const elementData: StripeElementsUpdateOptions = { amount: dataExpressShipping.amountTotal };
          elements?.update(elementData);
          const shippingRateList: ShippingRate[] = dataExpressShipping.shippingRates as ShippingRate[];
          const resolveData: ChangeResolveDetails = {
            // lineItems?: LineItem[] | undefined;
            shippingRates: shippingRateList || [],
            // applePay?: ApplePayUpdateOption | undefined;
          };
          // call event.resolve within 20 seconds
          resolve(resolveData);
        } else {
          reject();
        }
      },
      onError: () => {
        reject();
      },
    });
  };
  const onReady = (event: StripeExpressCheckoutElementReadyEvent) => {
    setIsLoading(false);
  };

  return (
    <div id="checkout-page">
      {isLoading && (
        <LoaderWrapperSmall>
          <CircularProgress />
        </LoaderWrapperSmall>
      )}
      <ExpressCheckoutElement
        options={expressCheckoutElementOptions}
        onConfirm={onConfirm}
        onClick={onClick}
        onShippingAddressChange={onShippingAddressChange}
        onReady={onReady}
      />

      {message && <div id="express_payment_message">{message}</div>}
    </div>
  );
};

export default StripeExpressCheckout;
