import { Card, CardContent, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNotify } from "react-admin";

import { processENV } from "../api";
import playbrush_logo from "../assets/gum_playbrush_logo.png";

const BASE_URL = (processENV.REACT_APP_KIDS_API_URL || "http://localhost:5000") + "/api/v2/auth/external";

const ENV_URL = (processENV.REACT_APP_SITE_URL || "http://localhost:3000") + "/auth/getToken";

const SSOLogin = () => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
        style={{ backgroundImage: "linear-gradient(-135deg,#c850c0,#4158d0)" }}
      >
        <Box>
          <Card>
            <CardContent>
              <Grid container justifyContent="center" direction="column" alignItems="center">
                <img src={playbrush_logo} alt="" width="500px" />
                <br />
                <img src="https://emoji.slack-edge.com/T03NV6ZDN/robin_head/9dce47287869454a.png" alt="Dayo" />
                <br />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <a href={`${BASE_URL}/login_url/microsoft?redirect_url=${ENV_URL}`}>
                        <Paper elevation={3}>
                          <Grid container justifyContent="center" alignItems="center" alignContent="center">
                            <img
                              src="https://learn.microsoft.com/en-us/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_signin_light.svg"
                              alt="Sign in with Mircosoft"
                            />
                          </Grid>
                        </Paper>
                      </a>
                      <br />
                      <Grid container justifyContent="center" alignItems="center" alignContent="center">
                        <small>
                          <i>Please use your Sunstar account</i>
                        </small>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

const SSOLoginCallback = () => {
  let notify = useNotify();
  var requestOptions: any = {
    method: "GET",
    mode: "cors",
  };
  fetch(`${BASE_URL}/validate_login${window.location.search}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      const user = JSON.parse(result);
      localStorage.setItem(
        "PB_Auth",
        JSON.stringify({
          id: user.username,
          fullName: user.name,
          state: user.state,
        })
      );
      window.location.href = "/";
    })
    .catch((error) => console.log("error", error));

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
      style={{ backgroundImage: "linear-gradient(-135deg,#c850c0,#4158d0)" }}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid container justifyContent="center" direction="column" alignItems="center">
              <img src={playbrush_logo} alt="" width="500px" />
              <br />
              <img src="https://emoji.slack-edge.com/T03NV6ZDN/robin_head/9dce47287869454a.png" alt="Dayo" />
              <h3>Logging you in...</h3>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export { SSOLogin, SSOLoginCallback };
